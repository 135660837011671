(function ($) {
  window.site = site || {};
  site.cartConfirm = site.cartConfirm || {};

  site.cartConfirm.launch = function (args) {
    var cartItems = args.ac_results;
    var templateData = { items: [] };
    var cartItem;

    i;

    // Normalize key names in cartItem object. 'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME'
    for (var i = 0; i < cartItems.length; i++) {
      if (cartItems[i].method !== 'addSku') {
        continue;
      }
      cartItem = cartItems[i].result.CARTITEM;
      templateData.items[i] = {};
      for (var key in cartItem) {
        if (cartItem.hasOwnProperty(key)) {
          templateData.items[i][key.replace(/\./, '_')] = cartItem[key];
        }
      }
      if (templateData.items[i]['prod_skuCount'] > 1) {
        templateData.items[i]['prod_THUMBNAIL_IMAGE'] = templateData.items[i]['sku_THUMBNAIL_IMAGE'];
      }
    }
    templateData.items_added = templateData.items.length;

    // $("html, body").animate({ scrollTop: 0 }, "fast");

    Drupal.cl.template.get({
      path: 'cart_confirm',
      object: templateData,
      callback: function (html) {
        generic.overlay.launch({
          content: html,
          includeBackground: true,
          hideOnClickOutside: true,
          includeCloseLink: false,
          cssStyle: {
            width: '100%',
            padding: '0px'
          }
        });
        // initialize close clicks
        $('.btn-continue, .overlay-background').on('click', function(e) {
          e.preventDefault();
          generic.overlay.hide();
        });
        // show appropriate confirm msg
        if (templateData.items.length === 1) {
          $('.cart-confirm-wrapper h2.item').removeClass('hidden');
          $('.cart-confirm-wrapper h2.items').addClass('hidden');
        }
      }
    });
  };
  site.cartConfirm.clearUrl = function () {
    window.location = window.location.href.replace(window.location.search, '');
  };
  Drupal.behaviors.cartConfirm = {
    attach: function (context, settings) {
      // Get the "Checkout" mobile main menu item
      var checkoutMenuItem = $('.field-mobile-menu').find('ul.menu').find('li.first').next().find('a span.checkout');

      $(document).on('addToCart.success', function (e, cartResult) {
        var args = {
          trans: cartResult.trans_data,
          items: cartResult.trans_data.order.items,
          newItemID: cartResult.ac_results[0].result.CARTITEM['prod.PRODUCT_ID']
        };

        site.cartConfirm.launch(cartResult);
        // Replace "Checkout" mobile main menu item with "Top Sellers" if there are 0 items on cart
        checkoutMenuItem.text('Checkout');
        checkoutMenuItem.parent().attr('href', '/checkout/viewcart/index.tmpl');
        checkoutMenuItem.parent().parent().addClass('parent-checkout');
      });
      $(document).on('addToCartKitItem.success', function (e, cartData) {
        var cartResult = cartData.cartResult;
        var kitNameComponents = [];
        var kitItem;
        var kitComponents = [];

        if (cartResult.messages[0].key === 'success.add_sku.collection.ukit') {
          $.each(cartData.cartResult.ac_results, function () {
            if (this.method !== 'add') {
              return true;
            }
            if (this.result.CARTITEM['prod.GIFTSET_COMPONENTS']) {
              this.method = this.result.METHOD;
              kitItem = this;
              kitNameComponents.push(this.result.CARTITEM['prod.SUB_LINE']);
            } else {
              kitNameComponents.unshift(this.result.CARTITEM['prod.SUB_LINE']);
              this.method = this.result.METHOD;
              kitComponents.push(this);
            }
          });
          if (typeof kitItem === 'undefined') {
            kitItem = kitComponents[0];
          }
          kitItem.result.CARTITEM['prod.SUB_LINE'] = kitNameComponents.join(' + ');
          cartResult.ac_results = [];
          cartResult.ac_results[0] = kitItem;
          cartResult.trans_data = cartResult.trans_data || {};
          cartResult.trans_data.order = cartResult.trans_data.order || {};
          site.cartConfirm.launch(cartResult);
          site.loading.hide();
        }
      });

      $(document).on('editCartKitItem.success', function () {
        site.cartConfirm.clearUrl();
      });

      $(document).on('cart.loaded', function (event, cartResult) {
        var latestItemIndex = cartResult.items_count - 1;
        var args = {
          trans: cartResult,
          items: cartResult.order.items
        };

        // site.cartConfirm.launch(args);
        // Replace "Checkout" mobile main menu item with "Top Sellers" if there are 0 items on cart
        if (cartResult.order.items.length < 1) {
          checkoutMenuItem.text('Top Sellers');
          checkoutMenuItem.parent().attr('href', '/top-sellers');
          checkoutMenuItem.parent().parent().toggleClass('parent-checkout');
        }
      });
    }
  };
})(jQuery);
